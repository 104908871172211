/* eslint-disable */
// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import singleLocation from './routes/location';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Locations map
  // Single location
  singleLocation,
});

/****
  *************
  *************
    Lazy Loading Images & Video
  *************
  *************
*****/
function loadDutchie(){
  var dutchieDiv = document.getElementById('dutchieEmbed');
  var dutchieID = dutchieDiv.getAttribute('data-id');
  var dutchieSource = dutchieDiv.getAttribute('data-src');
  var dutchieScriptTag = document.createElement('script');
  dutchieScriptTag.type = 'text/javascript';
  dutchieScriptTag.id = dutchieID;
  dutchieScriptTag.src = dutchieSource;
  dutchieDiv.appendChild(dutchieScriptTag);
  var s = document.getElementById(dutchieID);
  s.parentNode.insertBefore(dutchieScriptTag, s);
}
document.addEventListener('DOMContentLoaded', function() {
  var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy-video'));
    // videos
    if ('IntersectionObserver' in window) {
    var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove('lazy-video');
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function(lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });
  }
  // images
  if ('IntersectionObserver' in window) {
    var lazyloadImages = document.querySelectorAll('.lazy');
    var lazyloadImg = document.querySelectorAll('img.lazy');
    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          // main target
          var mainTarget = entry.target;
          // img tag
          var lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove('lazy');
          imageObserver.unobserve(lazyImage);
          // css background images
          var bgImage = entry.target.getAttribute('data-bgimage');
          if(bgImage) {
            mainTarget.style.backgroundImage = bgImage;
          }
          var bgSize = entry.target.getAttribute('data-bgsize');
          if(bgSize) {
            mainTarget.style.backgroundSize = bgSize;
          }
          var bgPosition = entry.target.getAttribute('data-bgposition');
          if(bgPosition) {
            mainTarget.style.backgroundPosition = bgPosition;
          }
          var bgRepeat = entry.target.getAttribute('data-bgrepeat');
          if(bgRepeat) {
            mainTarget.style.backgroundRepeat = bgRepeat;
          }

          entry.target.classList.remove('lazy');
          imageObserver.unobserve(entry.target);
        }
      });
    });
    lazyloadImages.forEach(function(mainTarget) {
      imageObserver.observe(mainTarget);
    });
  } else {
    var lazyloadThrottleTimeout;
    lazyloadImages = document.querySelectorAll('.lazy');

    function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function() {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function(img) {
          if (img.offsetTop < (window.innerHeight + scrollTop)) {
            img.src = img.dataset.src;
            img.classList.remove('lazy');
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    }

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
  // dutchie menus
  var dutchieMenu = document.getElementById('dutchieEmbed');
    // videos
    if ('IntersectionObserver' in window) {
    var lazyIframeObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          loadDutchie();
          entry.target.classList.remove('lazy-iframe');
          lazyIframeObserver.unobserve(entry.target);
        }
      });
    });
    if(dutchieMenu != null) {
      lazyIframeObserver.observe(dutchieMenu);
    }
  }
});

// load iframes on click
var navLoyalty = document.getElementById('navLoyalty');
var footerLoyalty = document.getElementById('footerLoyalty');
var bodyLoyalty = document.getElementById('bodyLoyalty');
var loyaltyIframe = document.getElementById('loyaltyiFrame');    
if(navLoyalty != null) {
  navLoyalty.onclick = function() {
    if(loyaltyIframe.getAttribute('data-src')) {
      loyaltyIframe.setAttribute('src',loyaltyIframe.getAttribute('data-src'));
    } 
  }
}
if(bodyLoyalty != null) {
  bodyLoyalty.onclick = function() {
    if(loyaltyIframe.getAttribute('data-src')) {
      loyaltyIframe.setAttribute('src',loyaltyIframe.getAttribute('data-src'));
    } 
  }
}
if(footerLoyalty != null) {
  footerLoyalty.onclick = function() {
    if(loyaltyIframe.getAttribute('data-src')) {
      loyaltyIframe.setAttribute('src',loyaltyIframe.getAttribute('data-src'));
    } 
  }
}
function load_iframes() {
  var vidDefer = document.getElementById('loyaltyiFrame');
  if(vidDefer.getAttribute('data-src')) {
    vidDefer.setAttribute('src',vidDefer.getAttribute('data-src'));
  } 
}

// Load Events
jQuery(document).ready(() => routes.loadEvents());
